import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import LatestDemoGrid from 'components/elements/Demos/LatestDemoGrid'
import ParseContent from 'components/shared/ParseContent'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Third Party
import styled from 'styled-components'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledLatestDemo = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
`

const LatestDemo: React.FC<BlogProps> = ({ fields }) => {
  const { allWpPost } = useStaticQuery<GatsbyTypes.latestDemoQuery>(graphql`
    query latestDemo {
      allWpPost(
        sort: { fields: date, order: DESC }
        filter: {
          categories: { nodes: { elemMatch: { databaseId: { eq: 121 } } } }
        }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const alteredPosts = posts as BlogBinkPosts
  const latest = alteredPosts[0].node.databaseId

  return (
    <StyledLatestDemo>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="latestdemo"
        showIds={[latest]}
      >
        <div className="py-xl-5 py-3">
          <div className="container">
            <ParseContent content={fields.title || ''} />
            <LatestDemoGrid fields={fields} />
          </div>
        </div>
      </BlogBink>
    </StyledLatestDemo>
  )
}

export default LatestDemo
