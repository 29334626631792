import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import FullCaseGrid from 'components/elements/Cases/FullCaseGrid'
import FeaturedCase from 'components/elements/Cases/FeaturedCase'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const AllCases: React.FC<BlogProps> = ({ fields }) => {
  const { allWpCase } = useStaticQuery<GatsbyTypes.allCasesQuery>(graphql`
    query allCases {
      allWpCase(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const posts: any = allWpCase.edges

  const firstPost = posts[0].node.databaseId

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        hideIds={[firstPost]}
        id="allcases"
      >
        <FeaturedCase fields={posts[0].node} />
        <div className="py-lg-5 py-3">
          <FullCaseGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default AllCases
