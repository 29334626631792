import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import Case from 'components/elements/Cases/Case'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const CasePreview: React.FC<BlogProps> = ({ fields }) => {
  const { allWpCase } = useStaticQuery<GatsbyTypes.CasePreviewQuery>(graphql`
    query CasePreview {
      allWpCase(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalCaseFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpCase.edges.filter(
    (e) => e.node.databaseId === fields.case?.databaseId
  )

  // @ts-ignore
  const post: GatsbyTypes.WpCase = allWpCase.edges.filter(
    (e) => e.node.databaseId === (fields.case?.databaseId || fields.case)
  )[0]?.node

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="casepreview"
        showIds={[fields.case?.databaseId || 0]}
      >
        <Case node={post} blogFields={fields} />
      </BlogBink>
    </section>
  )
}

export default CasePreview
