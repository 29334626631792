import React from 'react'

// Compenents
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Images
import WhiteFlowizeBlocks from 'img/white_flowize_blocks.inline.svg'

// Third Party
import styled from 'styled-components'

interface FullCaseProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpCase
}

const CaseExcerpt = styled(ParseContent)``

const StyledFullCase = styled.div`
  ${({ theme }) => theme.gradient.primaryGradientOpaque};
  height: fit-content;
  position: relative;

  @media screen and (max-width: 991px) {
    ${({ theme }) => theme.gradient.primaryGradientOpaqueMobile};
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  z-index: -1;
  height: 100%;
  position: absolute !important;
  right: 0;
  bottom: 0;

  @media (min-width: 992px) {
    width: 800px;
  }
`

const ContentContainer = styled.div`
  @media (min-width: 992px) {
    padding: 150px 100px 50px 80px;
  }

  @media (max-width: 991px) {
    padding-top: 200px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 130px;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
`

const StyledButton = styled(Button)`
  padding: 0;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.secondary};
  padding: 0px 20px 0px 60px;
  height: 105px;
  border-bottom-right-radius: 50px;
  font-size: 16px;

  @media (min-width: 992px) {
    min-width: 660px;
  }

  @media (max-width: 575px) {
    padding: 0px 0px 0px 5px;
    width: 200px;

    & span {
      font-size: 12px;
    }
  }
`

const FullCase: React.FC<FullCaseProps> = ({ node, blogFields }) => (
  <StyledFullCase>
    <StyledPlaatjie image={node.caseRecap?.shortdescriptionimage} alt="" />
    <div className="container position-relative h-100">
      <div className="row">
        <div className="col-lg-7">
          <ContentContainer>
            <SvgWrapper>
              <WhiteFlowizeBlocks />
            </SvgWrapper>
            <Overlay className="d-flex flex-column flex-sm-row align-items-center">
              <h2 className="mx-5 my-2 my-sm-0 font-weight-bold">
                <span className="text-white">Business case: &nbsp;</span>
                {node.title}
              </h2>
            </Overlay>
            <CaseExcerpt content={node.caseRecap?.shortdescription || ''} />
            <div className="pt-4 pt-sm-5 pb-4 pb-lg-0">
              <StyledButton
                to={node.uri}
                arrow
                className="secondary transparent hover-light me-4"
              >
                {blogFields.readmoretext}
              </StyledButton>
            </div>
          </ContentContainer>
        </div>
      </div>
    </div>
  </StyledFullCase>
)

export default FullCase
