import React from 'react'

// Compenents
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface LatestDemoProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const PostExcerpt = styled(ParseContent)`
  max-width: 100%;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const StyledLatestDemo = styled.div`
  height: fit-content;
  position: relative;
`

const ContentContainer = styled.div`
  @media (min-width: 576px) {
    padding: 30px;
  }
`

const LatestDemo: React.FC<LatestDemoProps> = ({ node }) => (
  <StyledLatestDemo>
    <div className="row">
      <div className="col-xl-7 col-lg-6 d-flex align-items-center">
        <Plaatjie image={node.recap?.secondimage} alt="" />
      </div>
      <div className="col-xl-5 col-lg-6 d-flex align-items-center">
        <ContentContainer>
          <PostExcerpt content={node.recap?.description || ''} />
        </ContentContainer>
      </div>
    </div>
  </StyledLatestDemo>
)

export default LatestDemo
