import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import PostGrid from 'components/elements/Posts/PostGrid'
import ParseContent from 'components/shared/ParseContent'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

const MoreDemos: React.FC<BlogProps> = ({ fields, location }) => {
  const { allWpPost } = useStaticQuery<GatsbyTypes.moreDemosQuery>(graphql`
    query moreDemos {
      allWpPost(
        sort: { order: DESC, fields: date }
        filter: {
          categories: { nodes: { elemMatch: { databaseId: { eq: 121 } } } }
        }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const alteredPosts = posts as BlogBinkPosts
  const hide = alteredPosts[0] ? alteredPosts[0].node.databaseId : 0

  const idList: number[] | undefined = fields.posts?.map(
    (post) => post?.databaseId || 0
  )

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="more-demos"
        limit={fields.withlimit === 'yes' ? 4 : 99}
        hideIds={[hide]}
        showIds={idList}
      >
        <div className="py-lg-5 py-3">
          <div className="container">
            <ParseContent content={fields.title || ''} />
          </div>
          <PostGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default MoreDemos
