import React from 'react'

// Components
import Blog from 'components/flex/Posts/Blog'
import Preview from 'components/flex/Posts/Preview'
import CasePreview from 'components/flex/Posts/CasePreview'
import PreviewDemos from 'components/flex/Posts/PreviewDemos'
import PreviewCases from 'components/flex/Posts/PreviewCases'
import LatestDemo from 'components/flex/Posts/LatestDemo'
import MoreDemos from 'components/flex/Posts/MoreDemos'
import PreviewAcademy from 'components/flex/Posts/PreviewAcademy'
import AllCases from 'components/flex/Posts/AllCases'

interface PostsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const PostsShell: React.FC<PostsShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    blog: Blog,
    preview: Preview,
    demopreview: PreviewDemos,
    casespreview: PreviewCases,
    academypreview: PreviewAcademy,
    casepreview: CasePreview,
    latestdemo: LatestDemo,
    moredemos: MoreDemos,
    allcases: AllCases,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default PostsShell
