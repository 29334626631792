import React from 'react'

// Components
import Button from 'components/elements/Buttons/Button'
import { useBlogBink, BlogBinkButton } from '@ubo/blog-bink'
import FullCase from 'components/elements/Cases/FullCase'

interface FullCaseGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const FullCaseGrid: React.FC<FullCaseGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container pb-lg-5 pb-3">
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-12 mt-lg-5 mt-3">
              <FullCase node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-lg-5 mt-3 text-center">
          <Button to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </Button>
        </div>
      )}
    </div>
  )
}

export default FullCaseGrid
