import React from 'react'

// Compenents
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Images
import WhiteFlowizeBlocks from 'img/white_flowize_blocks.inline.svg'

// Third Party
import styled, { css } from 'styled-components'

interface CaseProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpCase
}

const CaseExcerpt = styled(ParseContent)<{ background: any }>`
  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      & p,
      h2 {
        & span {
          color: ${theme.color.light} !important;
        }
      }
    `}

  & h3,h4,h5,h6 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const StyledCase = styled.div<{ background: any }>`
  ${({ background, theme }) =>
    background === 'primary' &&
    css`
      ${theme.gradient.primaryGradientOpaque};
      @media (max-width: 575px) {
        background: rgba(38, 64, 82, 0.9);
      }
      @media (min-width: 1560px) {
        background: linear-gradient(
          100deg,
          rgba(38, 64, 82, 1) 0%,
          rgba(38, 64, 82, 1) 65%,
          rgba(38, 64, 82, 0) 90%
        ) !important;
      }
      @media (min-width: 2400px) {
        background: linear-gradient(
          100deg,
          rgba(38, 64, 82, 1) 0%,
          rgba(38, 64, 82, 1) 75%,
          rgba(38, 64, 82, 0) 90%
        ) !important;
      }
      @media (min-width: 3200px) {
        background: linear-gradient(
          100deg,
          rgba(38, 64, 82, 1) 0%,
          rgba(38, 64, 82, 1) 79%,
          rgba(38, 64, 82, 0) 90%
        ) !important;
      }
    `}

  ${({ background, theme }) =>
    background === 'grey' &&
    css`
      ${theme.gradient.greyGradient};
      @media (max-width: 575px) {
        background: rgba(233, 233, 233, 0.9);
      }
    `}

  height: fit-content;
  position: relative;
  margin: 80px 0 0 0;

  @media screen and (max-width: 991px) {
    margin: 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  z-index: -1;
  height: 100%;
  width: 800px;
  position: absolute !important;
  right: 0;
  bottom: 0;
`

const ContentContainer = styled.div`
  @media (min-width: 768px) {
    padding: 60px 100px 60px 50px;
  }

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  @media (max-width: 991px) {
    background: inherit;

    &:before {
      background: inherit;
      box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
      filter: blur(10px);
    }
  }
`

const SvgWrapper = styled.div`
  & svg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
  }

  @media screen and (max-width: 991px) {
    & svg {
      height: 100%;
    }
  }
`

const Case: React.FC<CaseProps> = ({ node, blogFields }) => (
  <StyledCase background={blogFields.background}>
    <StyledPlaatjie image={node?.caseRecap?.image} alt="" />
    <div className="container position-relative h-100">
      <div className="row">
        <div className="col-lg-7">
          <ContentContainer>
            <CaseExcerpt
              background={blogFields.background}
              content={node?.caseRecap?.description || ''}
              className="mt-2 mt-sm-0"
            />
            <div className="py-lg-5 pt-4">
              <Button
                to={node?.uri}
                className="secondary filled hover-dark me-4"
              >
                {blogFields.readmoretext}
              </Button>
              {blogFields.background !== 'primary' && (
                <Button
                  to="/build-with-flowize/businesscases-successtories"
                  className="primary transparent hover-secondary"
                  arrow
                >
                  {blogFields.loadmoretext}
                </Button>
              )}
            </div>
          </ContentContainer>
        </div>
      </div>
    </div>
  </StyledCase>
)

export default Case
