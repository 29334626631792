import React from 'react'

// Images
// import flowizeicon from 'img/flowizeicon.svg'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface FeaturedCaseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpCase
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.secondary};
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
`

const Title = styled.h3`
  font-size: 40px;
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
`

const FeaturedCase: React.FC<FeaturedCaseProps> = ({ fields }) => (
  <section>
    <Wrapper className="py-4">
      <div className="container py-lg-5 py-2">
        <div className="row justify-content-evenly">
          <div className="col-xl-5 col-lg-6">
            <StyledPlaatjie
              image={fields.caseRecap?.shortdescriptionimage}
              alt=""
              loading="eager"
            />
          </div>
          <div className="col-lg-5 py-lg-5 pt-3">
            <SubTitle>Featured story</SubTitle>
            <div className="pt-2 pb-lg-4 pb-1 d-flex align-items-center">
              <Title>{fields.title}</Title>
            </div>
            <ParseContent
              content={fields.caseRecap?.featureddescription || ''}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  </section>
)

export default FeaturedCase
