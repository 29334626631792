import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import PostGrid from 'components/elements/Posts/PostGrid'
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Elements
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Third Party
import styled from 'styled-components'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledPreviewDemos = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
`

const PreviewDemos: React.FC<BlogProps> = ({ fields }) => {
  const { allWpPost } = useStaticQuery<GatsbyTypes.previewDemosQuery>(graphql`
    query previewDemos {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  const idList: number[] | undefined = fields.demoposts?.map(
    (post) => post?.databaseId || 0
  )

  return (
    <StyledPreviewDemos>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="preview-demos"
        showIds={idList}
        limit={Infinity}
      >
        <div className="py-lg-5 py-4">
          <div className="container">
            <ParseContent content={fields.title || ''} />
          </div>
          <PostGrid fields={fields} />
          <div className="container py-lg-5 py-3">
            <div className="d-flex justify-content-center">
              <Button
                className="secondary filled"
                to="/build-with-flowize/demos/"
              >
                {fields.loadmoretext}
              </Button>
            </div>
          </div>
        </div>
      </BlogBink>
    </StyledPreviewDemos>
  )
}

export default PreviewDemos
