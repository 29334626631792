import React from 'react'

// Components
import LatestDemo from 'components/elements/Demos/LatestDemo'

// Third Party
import { useBlogBink } from '@ubo/blog-bink'

interface LatestDemoGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const LatestDemoGrid: React.FC<LatestDemoGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container pb-xl-5 pb-3">
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-12 mt-xl-5 mt-3">
              <LatestDemo node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LatestDemoGrid
